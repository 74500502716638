import React from 'react';
import './App.css';
import { NavLink, Switch, Route } from 'react-router-dom';
import Home  from './Home.js';
import MathWriteups from './MathWriteups.js';
import Computing from './Computing.js';
import Brazil from './Brazil.js';
import Contact from './Contact.js';

const App = () => (
      <div className='app'>
        <Navigation />
        <Main />
      </div>
    );

const Navigation = () => (
      <nav>
        <ul>
          <li><NavLink to='/'>Home</NavLink></li>
          <li><NavLink to='/mathwriteups'>Research</NavLink></li>
          <li><NavLink to='/about'>CV</NavLink></li>
          <li><NavLink to='/contact'>Contact</NavLink></li>
          <li><NavLink to='/computing'>Computing</NavLink></li>
          <li><NavLink to='/br'>Brazil</NavLink></li>
        </ul>
      </nav>
    );


const About = () => (
  <div className='about'>
    <a href="http://lattes.cnpq.br/1188119082343760">CV</a>
  </div>
);


const Main = () => (
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/mathwriteups' component={MathWriteups}></Route>
        <Route exact path='/about' component={About}></Route>
        <Route exact path='/contact' component={Contact}></Route>
        <Route exact path='/computing' component={Computing}></Route>
        <Route exact path='/br' component={Brazil}></Route>
      </Switch>
    );

export default App;
