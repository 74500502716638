import React from 'react';

const MathWriteups = () => (
      <div className='mathwriteups'>
        <h3>Some of my talks</h3>

        <p></p>
        
        <p>
        <a href="slides/ift_2012-03-26/index.html">Talk in IFT, March 2012</a>
        {'\u00A0'}
        about deformations of BRST structure
        </p>
        
        <p>
        <a href="slides/Natal_2012/index.html">Talk in Natal</a>, May 2012
        about the massless SUGRA vertex in AdS
        </p>
        
        <p>
        Talk in Passa Quatro, August 2012:
        <a href="slides/PassaQuatro-slides/index.html">slides</a>,
        <a href="slides/PassaQuatro-singlepage/talk_PassaQuatro.html">single page</a>,
        on the use of Lie algebra cohomology in Type IIB SUGRA
        </p>
        
        
        <p>
        <a href="slides/talk_ITEP_2012/index.html">Talk at ITEP about linear dilaton</a>, December 2012
        </p>
        
        <p>
        <a href="slides/talk_Brasilia/index.html">Talk in Brasília about the integrated vertex</a>, November 2013
        </p>
        
        <p>
        <a href="slides/talk_Perimeter/index.html">Talk at the workshop on perturbative string theory, Perimeter Institute</a>, April 2015
        </p>
        
        <p>
        <a href="slides/talk_IFT-math_2016/index.html">BV formalism and string amplitudes</a>, mathematical physics workshop, SAIFR, June 2016
        </p>
        
        <p>
        <a href="slides/2019_workshop_quantum-symmetries/index.html">Symmetries, renormgroup, deformations, AdS/CFT</a>,
	&nbsp;
        <a href="https://www.ictp-saifr.org/workshop-on-quantum-symmetries/">workshop on quantum symmeties</a>, SAIFR, October 2019
        </p>
        
        <p>
        <a href="slides/talk_ESI_2021/index.html">BV formalism for string worldsheet</a>,
	&nbsp;
	Geometry for Higher Spin Gravity: Conformal Structures, PDEs, and Q-manifolds 
        <a href="https://www.esi.ac.at/events/e394/">(ESI, September 2021)</a>
        </p>

	<p>
	<a href="slides/talk_SFTCloud_2021/index.html">Equivariant BV formalism for string worldsheet</a>
	&nbsp;
	SFT@Cloud <a href="https://indico.cern.ch/event/1042834/">(Cloud, September 2021)</a>
	</p>

        <h3>Writeups</h3>

        <p></p>
        
        <p>
        <a href="math/bv/index.html">BV formalism</a>
        </p>
        
        <p>
        <a href="math/pure-spinor-formalism/index.html">Pure spinor formulas</a>
        </p>
        
        <p>
        <a href="math/deformations-of-AdS/finite-dimensional-vertex/index.html">Finite dimensional vertex</a>
        </p>

	<p>
	<a href="math/projector/index.html">BV description of AdS superstring</a>
	</p>
        
        <p>
        <a href="math/Renormgroup/index.html">Deformations, renormgroup, symmetries, AdS/CFT</a>
        </p>
        
        <p>
        <a href="math/DgAndBV/index.html">DGLA Dg and BV formalism</a> (also <a href="slides/talk_SFTCloud_2021/index.html">talk slides</a>)

        </p>

        <p>
        <a href="math/vector-fields/index.html">Normal form of nilpotent vector field near the tip of the pure spinor cone</a>
        </p>

        <p>
        <a href="math/text_brackets/index.html">Derived brackets in bosonic string worldsheet sigma-model</a>
        </p>

        <p>
        <a href="math/InsertionUsingBV/index.html">Insertion of vertex operators using BV</a>
        </p>

        <p>
        <a href="math/worldsheet-currents/index.html">Symmetry transformations of worldsheet currents</a>
        </p>


        <h3>Teaching</h3>

        <p></p>

        <p>
        Geometria Diferencial e Mecânica Clássica: <a href="http://www.ift.unesp.br/br/Home/pos-graduacao/gdmc.pdf">syllabus</a>,
        <a href="teaching/gdmc/index.html">notes</a>.
        </p>
        
      </div>
)

export default MathWriteups;
