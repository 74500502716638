import React from 'react';

const Computing = () => (
      <div className='computing'>
        <h1>Computer tips and tricks</h1>
        <p></p>

          <p>
          <a href="/slides/bystroTeX/slides-manual/index.html">BystroTeX</a> --- my method to insert LaTeX formulas into HTML
          </p>
          
          <p>
          <a href="https://github.com/amkhlv/mathpump3">MathPump</a> --- a tool for remote collaboration and teaching
          </p>
          
          <p>
          <a href="https://github.com/amkhlv/mathpump3/blob/master/docs/inkscape.md">Notes on how to use Inkscape</a> (not entirely trivial!)
          </p>

          <p>
          <a href="/scribbles/keyboard-layout/keyboard-layout.html">Notes on keyboard layouts</a>
          </p>

      </div>
)

export default Computing;
