import React from 'react';

const Home = () => (
      <div className='home'>


        <h2>May Russians and Ukrainians be happy and free of suffering</h2>
        <br/>
        <br/>

        <h1>▁▁▁║▁▁▁</h1>
        <br/>
        <br/>
        <br/>

        <p><a href="https://www.ictp-saifr.org/upcoming-seminars-and-activities/">Seminar schedule</a> at <a href="http://www.ictp-saifr.org/">SAIFR</a></p>
        
      </div>
)

export default Home;
