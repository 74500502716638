import React from 'react';

const Contact = () => (
  <div className='contact'>
    <h1>Contact Me</h1>
    <p>To contact me, go to the <a href="https://www.ift.unesp.br/#!/sobre-o-ift/pessoal85/docentes/">IFT roster</a> and search for  Andrey Mikhaylov</p>
  </div>
)

export default Contact;
